import { useMetaMask } from "metamask-react";
import { IMetaMaskContext } from "metamask-react/lib/metamask-context";
import React, { createContext, useContext, useState } from "react";
import { IState, SetStateType } from "../types";

const AppStateContext = createContext<
  | { metaMask: IMetaMaskContext; state: IState; setState: SetStateType }
  | undefined
>(undefined);

const DefaultState: IState = {
  mintInput: 0,
  mintFailed: false,
  processingMintTransaction: false,
};

function AppProvider({ children }: { children: React.ReactNode }) {
  const metaMask = useMetaMask();
  const [state, _setState] = useState<IState>(DefaultState);

  const setState: SetStateType = (ob) => {
    _setState((pre: any) => ({
      ...pre,
      ...ob,
    }));
  };

  const value = { metaMask, state, setState };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

function useApp() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}

export { AppProvider, useApp };
