import { toast } from "react-toastify";
import { Web3Helper } from "../../api/contract";
import { useApp } from "../../context/AppContext";
import {
  FlexContainer,
  ImageContainer,
  YellowButton,
} from "../shared/containers";
import {
  MintCard,
  MintControlButton,
  MintController,
  MintingContainer,
  MintNumber,
} from "./Minting.styles";
import ProgressBar from "./ProgressBar";
import TickLogo from "../../assets/tick.svg";
import TransactionLinks from "./TransactionLinks";

export default function Minting() {
  const cards = [
    ["SUPPLY", Web3Helper.maxSupply],
    ["PRICE", `${Web3Helper.mintPrice("converted")} ETH`],
    ["MAX", `${Web3Helper.tokensPerMint} Per Wallet`],
  ];
  const {
    state: { mintInput, ...state },
    setState,
  } = useApp();

  const { price, priceType } = Web3Helper.calculateMintPrice(mintInput);

  const mintedNFTPercentage = (
    ((state.totalMintedNFTs || 0) / Web3Helper.maxSupply) *
    100
  ).toFixed(2);

  const handleControlButton: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    if (e.currentTarget.id === "button-add") {
      if (mintInput >= Web3Helper.tokensPerMint) return;
      setState({ mintInput: mintInput + 1 });
    } else if (e.currentTarget.id === "button-sub") {
      if (mintInput === 0) return;
      setState({ mintInput: mintInput - 1 });
    }
  };

  const handleMint: React.MouseEventHandler<HTMLButtonElement> = async () => {
    setState({ processingMintTransaction: true });
    let txn: any = undefined;
    try {
      if (state.publicMintActive) {
        txn = await Web3Helper.makePublicSaleMintTransaction(mintInput);
      } else if (state.presaleMintActive) {
        txn = await Web3Helper.makePreSaleMintTransaction(mintInput);
      } else {
        throw new Error("Minting is not live");
      }
      toast.success("Transaction Successful");
      console.log(txn);
    } catch (err) {
      console.log("Minting Failed", err);
      setState({ mintFailed: true });
      toast.error("Minting Failed");
    } finally {
      console.log({ txn });
      if (txn) setState({ processingMintTransaction: false, transaction: txn });
      else setState({ processingMintTransaction: false });
    }
  };

  return (
    <MintingContainer>
      <FlexContainer
        style={{ marginBottom: "2rem", justifyContent: "space-around" }}
      >
        {cards.map((el, i) => {
          return (
            <MintCard key={i}>
              {el[0]}: <span className="yellow-text">{el[1]}</span>
            </MintCard>
          );
        })}
      </FlexContainer>
      <div style={{ marginBottom: "1rem" }}>
        <span style={{ fontWeight: "bold" }}>
          {mintedNFTPercentage}% Minted
        </span>
        <span>
          ({state.totalMintedNFTs || 0}/{Web3Helper.maxSupply})
        </span>
      </div>
      <ProgressBar completed={mintedNFTPercentage} />
      {state.transaction ? (
        <>
          <YellowButton
            disable={true}
            style={{ margin: "2rem" }}
            className="inline-flex center"
          >
            <ImageContainer src={TickLogo} style={{ margin: "auto 8px" }} />
            MINT IS SUCCESSFUL
          </YellowButton>
          <h2 className="yellow-text">Check your NFTs on:</h2>
          <TransactionLinks transaction={state.transaction} />
        </>
      ) : (
        <>
          <MintController>
            <FlexContainer style={{ marginTop: "1rem" }}>
              <MintControlButton onClick={handleControlButton} id="button-sub">
                -
              </MintControlButton>
              <MintNumber>{mintInput}</MintNumber>
              <MintControlButton onClick={handleControlButton} id="button-add">
                +
              </MintControlButton>
            </FlexContainer>
            <div style={{ marginTop: "1rem" }}>
              <span>TOTAL </span>
              <span className="yellow-text">
                {price} {priceType}
              </span>
            </div>
          </MintController>

          <YellowButton
            style={{ marginTop: "2rem" }}
            onClick={handleMint}
            disabled={price === "0" || state.processingMintTransaction}
          >
            {state.processingMintTransaction
              ? "Waiting for transaction"
              : "Mint Now"}
          </YellowButton>
        </>
      )}
    </MintingContainer>
  );
}
