export default function ProgressBar({
  completed,
}: {
  completed: number | string;
}) {
  return (
    <div
      style={{
        width: "80%",
        height: "5px",
        borderRadius: "5px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          borderRadius: "5px",
          width: `${completed}%`,
          height: "100%",
          backgroundColor: "yellow",
        }}
      ></div>
    </div>
  );
}
