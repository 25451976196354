export interface IState {
  presaleMintActive?: boolean;
  publicMintActive?: boolean;
  mintInput: number;
  mintFailed: boolean;
  processingMintTransaction: boolean;
  isWhitelisted?: boolean;
  totalMintedNFTs?: number;
  transaction?: Record<string, any>;
}

export type SetStateType = (ob: Partial<IState>) => any;

export enum PriceType {
  ETH = "ETH",
  MATIC = "MATIC",
}

export enum ChainType {
  rinkeby = "rinkeby",
  polygon = "polygon",
}
