import { ChainType, PriceType, SetStateType } from "./../types/";
import { Contract } from "web3-eth-contract";
import Web3 from "web3";
import { abi } from "./build";
import { IMetaMaskContext } from "metamask-react/lib/metamask-context";
import { apiGetMerkleProof } from "./backend";

const CHAIN = process.env.REACT_APP_CHAIN || "rinkeby";

export class Web3Helper {
  private static readonly CONTRACT_ADDRESS =
    process.env.REACT_APP_CONTRACT_ADDRESS!;

  private static metaMask: IMetaMaskContext;

  private static contract: Contract;
  private static web3: Web3;

  public static presaleMintActive: boolean;
  public static publicMintActive: boolean;
  public static contractInfo: any;

  static get ethereum() {
    return (window as any).ethereum;
  }

  static async updateConnectStatus(cb: SetStateType) {
    // @ts-ignore
    this.contract = new this.web3.eth.Contract(abi, this.CONTRACT_ADDRESS);

    this.contractInfo = await this.contract.methods.getInfo().call();
    this.publicMintActive = await this.contract.methods.mintingActive().call();
    this.presaleMintActive = await this.contract.methods.presaleActive().call();

    cb({
      presaleMintActive: this.presaleMintActive,
      publicMintActive: this.publicMintActive,
    });
  }

  static async initWeb3(metaMask: IMetaMaskContext, cb: SetStateType) {
    this.metaMask = metaMask;
    this.web3 = new Web3(metaMask.ethereum);

    await this.checkChain();
    await this.updateConnectStatus(cb);
  }

  static get tokensPerMint(): number {
    return this.contractInfo.deploymentConfig.tokensPerMint;
  }

  static get maxSupply(): number {
    return this.contractInfo.deploymentConfig.maxSupply;
  }

  static async getTotalMintedNFTs() {
    return await this.contract.methods.totalSupply().call();
  }

  static mintPrice(format: "bigInt" | "converted") {
    const price = this.contractInfo.deploymentConfig.mintPrice;
    if (format === "bigInt") {
      return price;
    } else {
      return this.web3.utils.fromWei(price);
    }
  }

  static calculateMintPrice(mintInput: number) {
    const totalPriceWei =
      BigInt(this.contractInfo.deploymentConfig.mintPrice) * BigInt(mintInput);

    let priceType = "";
    if (CHAIN === ChainType.rinkeby) {
      priceType = PriceType.ETH;
    } else if (CHAIN === ChainType.polygon) {
      priceType = PriceType.MATIC;
    }
    const price = this.web3.utils.fromWei(totalPriceWei.toString(), "ether");
    return { price, priceType };
  }

  static async isWhitelisted() {
    const merkleJson = await apiGetMerkleProof(
      CHAIN,
      this.metaMask.account!,
      this.CONTRACT_ADDRESS
    );

    const whitelisted = await this.contract.methods
      .isWhitelisted(this.metaMask.account, merkleJson)
      .call();
    return whitelisted;
  }

  static async makePublicSaleMintTransaction(mintInput: number) {
    const price = BigInt(mintInput) * BigInt(this.mintPrice("bigInt"));
    const mintTransaction = await this.contract.methods.mint(mintInput).send({
      from: this.metaMask.account,
      value: price.toString(),
    });
    return mintTransaction;
  }

  static async makePreSaleMintTransaction(mintInput: number) {
    const merkleJson = await apiGetMerkleProof(
      CHAIN,
      this.metaMask.account!,
      this.CONTRACT_ADDRESS
    );

    const price = BigInt(mintInput) * BigInt(this.mintPrice("bigInt"));
    const presaleMintTransaction = await this.contract.methods
      .presaleMint(mintInput, merkleJson)
      .send({ from: this.metaMask.account, value: price.toString() });
    return presaleMintTransaction;
  }

  static async checkChain() {
    let chainId: number;
    if (CHAIN === ChainType.rinkeby) {
      chainId = 4;
    } else if (CHAIN === ChainType.polygon) {
      chainId = 137;
    } else {
      throw new Error("Chain not supported");
    }

    if (parseInt(this.ethereum.networkVersion) !== chainId) {
      try {
        await this.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: this.web3.utils.toHex(chainId) }],
        });
      } catch (err: any) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (err.code === 4902) {
          try {
            if (CHAIN === "rinkeby") {
              await this.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainName: "Rinkeby Test Network",
                    chainId: this.web3.utils.toHex(chainId),
                    nativeCurrency: {
                      name: "ETH",
                      decimals: 18,
                      symbol: "ETH",
                    },
                    rpcUrls: [
                      "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
                    ],
                  },
                ],
              });
            } else if (CHAIN === "polygon") {
              await this.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainName: "Polygon Mainnet",
                    chainId: this.web3.utils.toHex(chainId),
                    nativeCurrency: {
                      name: "MATIC",
                      decimals: 18,
                      symbol: "MATIC",
                    },
                    rpcUrls: ["https://polygon-rpc.com/"],
                  },
                ],
              });
            }
            // updateConnectStatus();
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  }
}
