export const formatNumberTwoDigits = (n: number) => {
  return n.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const shortenString = (content: string, start = 4, end = 4) => {
  if (content.length <= start + end) {
    return content;
  }
  return `${content.slice(0, start)}...${content.slice(content.length - end)}`;
};
