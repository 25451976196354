import { HeaderContainer, HeaderLink, LogoContainer } from "./Header.styles";
import Logo from "../../assets/logo.svg";
import TwitterLogo from "../../assets/twitter.svg";
import InstaLogo from "../../assets/insta.svg";
import { FlexContainer } from "../shared/containers";
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";
import { useApp } from "../../context/AppContext";

export default function Header() {
  const { state } = useApp();
  return (
    <HeaderContainer>
      <LogoContainer src={Logo} alt="logo" />
      <FlexContainer>
        <HeaderLink
          href="https://twitter.com/cryptoarabnft"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={TwitterLogo} alt="twitter" />
        </HeaderLink>
        <HeaderLink
          href="https://www.instagram.com/cryptoarabnft/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InstaLogo} alt="insta" />
        </HeaderLink>
        {state.presaleMintActive && <ConnectWalletButton size="sm" />}
      </FlexContainer>
    </HeaderContainer>
  );
}
