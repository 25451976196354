import { Card } from "./../LandingPage/LandingPage.styles";
import styled from "styled-components";
import { FlexContainer } from "./../shared/containers";

export const MintingContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  margin: 1.5rem auto auto;
`;

export const MintHeading = styled.h1`
  font-family: "Squada One";
  color: white;
  font-size: 3.5rem;
  margin: 2rem auto 0;
`;

export const MintCard = styled(Card)`
  background: rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  width: 200px;
  text-align: center;
  backdrop-filter: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.8rem 1.3rem;
`;

export const MintController = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 142px;
  padding: 1rem 0;
  align-items: center;
  margin: 0;
`;

export const MintControlButton = styled.button`
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(34px);
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 4px;
  font-size: 2rem;
  margin: 0 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

export const MintNumber = styled.div`
  width: 87px;
  height: 45px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const TransactionLinksContainer = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
  margin: 1rem;
`;

export const TransactionLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(34px);
  border-radius: 24px;
  width: 168px;
  font-size: 1rem;
  text-align: center;
  padding: 0.6rem;
`;
