import Header from "../Header/Header";
import { InfoPara, LandingPageContainer } from "./LandingPage.styles";
import { useApp } from "../../context/AppContext";
import { useEffect } from "react";
import { Web3Helper } from "../../api/contract";
import Minting from "../Minting/Minting";
import { MintHeading } from "../Minting/Minting.styles";
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";
import { RedButton } from "../shared/containers";

export default function LandingPage() {
  const { metaMask, setState, state } = useApp();

  // to initiate web3 when account is connected
  useEffect(() => {
    const init = async () => {
      await Web3Helper.initWeb3(metaMask, setState);
      const isWhitelisted = await Web3Helper.isWhitelisted();
      setState({ isWhitelisted });
      setState({ totalMintedNFTs: await Web3Helper.getTotalMintedNFTs() });
    };

    if (metaMask.account) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaMask.account]);

  return (
    <LandingPageContainer>
      <Header />
      <MintHeading>CRYPTO ARABS MINT</MintHeading>

      {state.presaleMintActive && state.isWhitelisted ? (
        <Minting />
      ) : (
        <>
          <InfoPara>
            For the first time in history, an Arabic Cartoon show “Shaabiat Al
            Cartoon” has been turned into an NFT collection consisting of 9,999
            unique art pieces that have been derived from the 10 main characters
            within the show with massive utility attached to them.
          </InfoPara>
          {state.isWhitelisted === false ? (
            <RedButton disabled={true}>You are not whitelisted</RedButton>
          ) : (
            <ConnectWalletButton />
          )}
        </>
      )}
    </LandingPageContainer>
  );
}
