import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.img``;

export const YellowButton = styled.button<any>`
  background: linear-gradient(180deg, #ffe234 0%, #d0bd42 50.96%, #d0ae42 100%);
  border: none;
  width: ${(props: any) => (props.size === "sm" ? "150px" : "246px")};
  padding: ${(props: any) => (props.size === "sm" ? "0.6rem" : "0.9rem")};
  color: white;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export const RedButton = styled(YellowButton)`
  background: red;

  &:hover {
    cursor: default;
  }
`;
