import { ImageContainer } from "../shared/containers";
import { TransactionLink, TransactionLinksContainer } from "./Minting.styles";
import OpenSeaLogo from "../../assets/opensea.svg";
import EtherScanLogo from "../../assets/etherscan.svg";

function TransactionLinks({ transaction }: any) {
  const LinkLogo = ({ logo }: any) => (
    <ImageContainer src={logo} style={{ marginRight: "7px" }} />
  );

  const TransactionLinksRow = (tokenId: number) => (
    <TransactionLinksContainer>
      <h3 className="yellow-text">Token #{tokenId}.</h3>
      <TransactionLink
        href={`${process.env.REACT_APP_OPEN_SEA_TOKEN_URL}/${tokenId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkLogo logo={OpenSeaLogo} />
        Open Sea
      </TransactionLink>
      <TransactionLink
        href={`${process.env.REACT_APP_ETHER_SCAN_URL}/${transaction.transactionHash}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkLogo logo={EtherScanLogo} />
        Etherscan
      </TransactionLink>
    </TransactionLinksContainer>
  );

  let transfer: any[] = transaction.events.Transfer;

  if (!Array.isArray(transfer)) {
    transfer = [transfer];
  }

  return (
    <>
      {transfer.map((t, i) => (
        <div key={i}>{TransactionLinksRow(t.returnValues.tokenId)}</div>
      ))}
    </>
  );
}

export default TransactionLinks;
