import { FlexContainer } from "./../shared/containers";
import styled from "styled-components";

export const HeaderContainer = styled(FlexContainer)`
  width: 90%;
  justify-content: space-between;
  padding: 2rem 4rem;
`;

export const LogoContainer = styled.img``;

export const HeaderLink = styled.a`
  color: white;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  margin: 0 1.5rem;
  font-size: 1.2rem;

  &:hover {
    cursor: pointer;
  }
`;
