import styled from "styled-components";
import { FlexContainer } from "../shared/containers";
import NFTImages from "../../assets/nfts.png";

export const LandingPageContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  background-image: url(${NFTImages});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  height: 100%;
`;

export const Card = styled.div`
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 9px;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem 2rem;
  margin: auto 2rem;
`;

// export const VerticalLine = styled.hr`
//   border: none;
//   border-left: 3px solid rgba(255, 255, 255, 0.3);
//   height: 100px;
//   width: 1px;
// `;

// export const ClockContainer = styled(Card)`
//   margin: 2rem;
//   border-radius: 12px;
//   width: 22rem;
// `;

// export const ClockHand = styled.div`
//   margin: 0 1rem;
//   text-align: center;
//   font-size: 3rem;
//   font-weight: 400;
// `;

export const InfoPara = styled.p`
  width: 50%;
  text-align: center;
  margin: 1rem auto 3rem;
`;
