export const apiGetMerkleProof = async (
  chain: string,
  walletAddress: string,
  contractAddress: string
) => {
  // const query = new URLSearchParams({
  //   chain,
  //   include: "merkle_proofs",
  // });

  const res = await fetch(`${process.env.REACT_APP_API_URL!}`, {
    method: "GET",
  });
  const json = await res.json();

  const contractInfo = json.contracts.filter(
    (contract: any) =>
      contract.address.toLowerCase() === contractAddress.toLowerCase()
  );
  const merkleProofs = contractInfo[0].merkle_proofs || {};
  const merkleProof = merkleProofs[walletAddress.toLowerCase()] || [];
  return merkleProof;
};
