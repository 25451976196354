import { useApp } from "../../context/AppContext";
import { shortenString } from "../../utils";
import { YellowButton } from "../shared/containers";

interface Props {
  size?: "sm" | "lg";
}

export default function ConnectWalletButton({ size }: Props) {
  const { metaMask } = useApp();
  let label: string;
  let disabled = true;

  switch (metaMask.status) {
    case "initializing":
      label = "Connecting Account";
      break;
    case "connecting":
      label = "Connecting Account";
      break;
    case "connected":
      label = shortenString(metaMask.account, 9, size === "sm" ? 3 : 9);
      break;
    case "unavailable":
      label = "METAMASK UNAVAILABLE";
      break;
    default:
      label = "Connect Wallet";
      disabled = false;
  }

  return (
    <YellowButton disabled={disabled} onClick={metaMask.connect} size={size}>
      {label}
    </YellowButton>
  );
}
