import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AppProvider } from "../context/AppContext";
import LandingPage from "./LandingPage/LandingPage";
import { MetaMaskProvider } from "metamask-react";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <div className="app">
      <MetaMaskProvider>
        <AppProvider>
          <LandingPage />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            theme="dark"
          />
        </AppProvider>
      </MetaMaskProvider>
    </div>
  );
}
